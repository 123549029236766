
























































































































































































































































































import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
import BirthdayFieldDialog from '@/pages/appointment/components/BirthdayFieldDialog.vue'
import SelectField from '@/pages/appointment/components/SelectField.vue'
import { formatDateWithoutYear } from '@/lib/date-util'
import TitleComp from '@/pages/appointment/components/Title.vue'

export type DataType = {
  valid: boolean;
  requiredRules: Function[];
  requiredSelectRules: Function[];
  emailRules: Function[];
  phoneNumberRules: Function[];
}

export default Vue.extend({
  name: 'Profile',
  components: {
    BirthdayFieldDialog,
    SelectField,
    TitleComp
  },
  data (): DataType {
    return {
      valid: false,
      requiredRules: [
        (v: string) => !!v || '入力してください'
      ],
      requiredSelectRules: [
        (v: string) => !!v || '選択してください'
      ],
      emailRules: [
        (v: string) => !!v || '入力してください',
        (v: string) => /[ -~]+@[ -~]+\.[ -~]+$/.test(v) || '形式が正しくありません'
      ],
      phoneNumberRules: [
        (v: string) => !!v || '入力してください',
        (v: string) => /^[0-9][0-9-]{5,11}[0-9]$/.test(v) || '形式が正しくありません'
      ]
    }
  },
  computed: {
    ...mapGetters('appointments', {
      selectedMenu: 'selectedMenu',
      firstHopeDay: 'firstHopeDay',
      firstHopeHour: 'firstHopeHour',
      secondHopeDay: 'secondHopeDay',
      secondHopeHour: 'secondHopeHour',
      thirdHopeDay: 'thirdHopeDay',
      thirdHopeHour: 'thirdHopeHour',
      nameValue: 'name',
      kanaValue: 'kana',
      birthdayValue: 'birthday',
      age: 'age',
      sexValue: 'sex',
      phoneValue: 'phone',
      emailValue: 'email',
      contactPossibleHourValue: 'contactPossibleHour',
      initialDiagnosisValue: 'initialDiagnosis',
      medicalCardNumberValue: 'medicalCardNumber',
      requestsAndEtcValue: 'requestsAndEtc',
      complete: 'complete'
    }),
    name: {
      get (): string | null {
        return this.nameValue
      },
      set (value: string): void {
        this.setName(value)
      }
    },
    kana: {
      get (): string | null {
        return this.kanaValue
      },
      set (value: string): void {
        this.setKana(value)
      }
    },
    birthday: {
      get (): Date | null {
        return this.birthdayValue
      },
      set (value: Date): void {
        this.setBirthday(value)
      }
    },
    sex: {
      get (): string | null {
        return this.sexValue
      },
      set (value: string): void {
        this.setSex(value)
      }
    },
    phone: {
      get (): string | null {
        return this.phoneValue
      },
      set (value: string): void {
        this.setPhone(value)
      }
    },
    email: {
      get (): string | null {
        return this.emailValue
      },
      set (value: string): void {
        this.setEmail(value)
      }
    },
    contactPossibleHour: {
      get (): string | null {
        return this.contactPossibleHourValue
      },
      set (value: string): void {
        this.setContactPossibleHour(value)
      }
    },
    initialDiagnosis: {
      get (): boolean {
        return this.initialDiagnosisValue
      },
      set (value: boolean): void {
        this.setInitialDiagnosis(value)
      }
    },
    medicalCardNumber: {
      get (): string | null {
        return this.medicalCardNumberValue
      },
      set (value: string): void {
        this.setMedicalCardNumber(value)
      }
    },
    requestsAndEtc: {
      get (): string | null {
        return this.requestsAndEtcValue
      },
      set (value: string): void {
        this.setRequestsAndEtc(value)
      }
    },
    contactHourItems (): string[] {
      // TODO 営業時間
      return ['時間を問わない', '9 時台', '10 時台', '11 時台', '12 時台', '14 時台', '15 時台', '16 時台', '17 時台']
    },
    formattedFirstHopeDay (): string | null {
      return formatDateWithoutYear(this.firstHopeDay)
    },
    formattedSecondHopeDay (): string | null {
      return formatDateWithoutYear(this.secondHopeDay)
    },
    formattedThirdHopeDay (): string | null {
      return formatDateWithoutYear(this.thirdHopeDay)
    }
  },
  methods: {
    ...mapActions('appointments', [
      'setName',
      'setKana',
      'setBirthday',
      'setSex',
      'setPhone',
      'setEmail',
      'setContactPossibleHour',
      'setInitialDiagnosis',
      'setMedicalCardNumber',
      'setRequestsAndEtc',
      'seProfile'
    ]),
    clickNextBtn (): void {
      if (!this.complete) {
        this.seProfile()
        this.$router.push({ name: 'page-confirm' })
      }
    }
  }
})
