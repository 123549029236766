


































import Vue from 'vue'
import { formatDate } from '@/lib/date-util'

export type DataType = {
  modal: boolean;
}

export default Vue.extend({
  name: 'BirthdayField',
  props: {
    value: {
      type: String,
      default: null
    },
    rules: {
      type: Array,
      required: false,
      default: null
    }
  },
  data (): DataType {
    return {
      modal: false
    }
  },
  computed: {
    day: {
      get (): string | null {
        return this.value
      },
      set (newVal: string): void {
        if (this.value !== newVal) this.$emit('input', newVal)
      }
    },
    formatDay (): string | null {
      return formatDate(this.day)
    }
  },
  watch: {
    modal (val: boolean) {
      /* eslint-disable */
      let picker: any = null
      val && setTimeout(() => (
        picker = this.$refs.picker,
        picker.activePicker = 'YEAR'
      ))
      /* eslint-enable */
    }
  }
})
